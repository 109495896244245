import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
const REACT_APP_NODE_API_BASE_URL = process.env.REACT_APP_NODE_API_BASE_URL;

const SQLQueryExecutor = () => {
  const [query, setQuery] = useState("");
  const [result, setResult] = useState("");
  const [loading, setLoading] = useState(false);

  const executeQuery = async () => {
    setLoading(true);
    setResult("Loading...");
    try {
      const response = await fetch(
        `${REACT_APP_NODE_API_BASE_URL}/execute-query`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query,
          }),
        }
      );
      const resultText = await response.text();
      setResult(resultText);
    } catch (error) {
      setResult("Error executing query. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const executeQueryDownload = async () => {
    setLoading(true);
    setResult("Loading...");
    try {
      const response = await fetch(
        `${REACT_APP_NODE_API_BASE_URL}/download-query-data`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch data.");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "query-data.csv"; // Change the filename as needed
      document.body.appendChild(a);
      a.click();
      a.remove();
      setResult("File downloaded successfully.");
    } catch (error) {
      setResult("Error executing query. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const DynamicTable = ({ htmlContent }) => {
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
  };

  return (
    <div className="min-vh-100 d-flex flex-column align-items-center bg-light">
      {/* Main Content */}
      <div className="container py-5">
        <div
          className="card shadow-sm p-4 mx-auto"
          style={{ maxWidth: "800px" }}
        >
          <h2 className="text-center text-primary mb-4">
            Execute Your SQL Query
          </h2>

          <textarea
            className="form-control mb-3"
            rows="6"
            placeholder="Enter your SQL query here"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          ></textarea>

          <button
            className="btn btn-primary w-100 mb-3"
            onClick={executeQuery}
            disabled={loading}
          >
            {loading ? "Executing..." : "Execute Query"}
          </button>

          <button
            className="btn btn-primary w-100 mb-3"
            onClick={executeQueryDownload}
          >
            Download CSV
          </button>

          <div
            id="result"
            className="border rounded p-3 bg-light"
            style={{ overflow: "auto", maxHeight: "300px" }}
          >
            <DynamicTable htmlContent={result} />
          </div>
        </div>

        {/* Suggestion Block */}
        <div className="card mt-4 p-4 shadow-sm">
          <h4 className="text-secondary mb-3">Example Query:</h4>
          <pre className="bg-dark text-white p-3 rounded">
            SELECT username, slug, name, whatsapp, created_at AS signup_date
            FROM users WHERE id = 12333
          </pre>
        </div>
      </div>
    </div>
  );
};

export default SQLQueryExecutor;
